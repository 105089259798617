<template>
  <div>
    <div class="tool_bar">
      <div class="tab">
        <div class="tab_item active">对话</div>
        <div class="tab_item">咨询师详情</div>
      </div>
      <div class="flex_align_center countdown">
        <img src="../assets/images/daojishi.png" />
        <count-down
          class="countdown"
          @end_callback="countdown_end()"
          :currentTime="1538983555"
          :startTime="1538983555"
          :endTime="1538985555"
          endText="已结束"
          secondsTxt=""
        >
        </count-down>
        <!--<label class="countdown">00:58:12</label>-->
      </div>
    </div>

    <div class="talk_wrap" id="chat-box">
      <div class="info_card">
        <div style="width: 45%">
          <div>
            <b class="name">{{ zxsInfo.name }}</b>
            <span class="xw">
              <span v-for="(item, index) in sectLable" :key="index">
                {{ item }} <i v-if="index < sectLable.length - 1">|</i>
              </span>
            </span>
          </div>
          <div class="mt10 mb10 fs14">
            {{ zxsInfo.sex }}
            <span v-if="zxsInfo.workYear">| 从业{{ zxsInfo.workYear }}年</span>
          </div>
          <div class="mt10 mb10 fs14">
            教育背景：<span style="color: #333333">{{
              zxsInfo.education
            }}</span>
          </div>
          <div class="mt10 mb10 fs14 ly_box">
            <span>擅长领域：</span>
            <label class="tag" v-for="(item, index) in field" :key="index">{{
              item
            }}</label>
            <!-- <label class="tag">学业</label>
            <label class="tag">学业</label> -->
          </div>
        </div>

        <div class="job_info">
          <!-- <div class="flex_align_center">
            <img src="../assets/images/gongsi.png"/>
            <div class="time">2018.07 - 2021.03</div>
            <div class="des">就职于某某单位</div>
          </div>
          <div class="flex_align_center">
            <img src="../assets/images/gongsi.png"/>
            <div class="time">2018.07 - 2021.03</div>
            <div class="des">就读心理医学院研究从事临床研究</div>
          </div> -->
        </div>
      </div>
      <div class="talk_time">2021年11月7日 上午10:41</div>
      <div class="msg-box clearfix" v-for="(i, index) in chatList" :key="index">
        <div class="msg" :class="{ isSelf: i.userType === 1 }">
          <div class="user-head" v-if="i.userType === 1">
            <img
              :src="
                userHeadImg
                  ? userHeadImg
                  : require('../assets/images/微信图片_20211108192003.jpg')
              "
            />
          </div>
          <div class="user-head" v-if="i.userType === 2">
            <img
              :src="
                consultantHeadImg
                  ? api + consultantHeadImg
                  : require('../assets/images/微信图片_20211108192003.jpg')
              "
            />
          </div>
          <div class="user-msg" :class="{ self: i.userType === 1 }">
            <span>{{i.text}}</span>
            <!-- <neirong :payload="i"></neirong> -->
          </div>
        </div>
      </div>
    </div>

    <div class="input-box">
      <div class="chat-tool-bar">
        <el-popover placement="top" width="400" trigger="click">
          <div class="emojis">
            <div
              v-for="item in emojiMap"
              :key="item"
              @click="chooseEmoji(item)"
              class="emoji"
            >
            {{item}}
              <!-- <img
                :src="emojiUrl + emojiMap[item]"
                style="width: 30px; height: 30px"
              /> -->
            </div>
          </div>
          <img
            slot="reference"
            class="icon"
            src="../assets/images/expression.png"
          />
        </el-popover>
      </div>
      <textarea
        v-model="textarea"
        rows="4"
        autofocus
        placeholder="开始对话..."
        @input="inputFn"
        @blur="blurFn"
        maxlength="200"
        @keyup.enter.prevent="send"
      ></textarea>
      <div style="display: flex; flex-direction: row-reverse">
        <div class="send_btn" @click="send">发送</div>
      </div>
    </div>
    <el-dialog
      :title="Ors.docName"
      :visible.sync="dialogTableVisible"
      width="700px"
      class="ors_diglog"
      :close-on-click-modal="false"
    >
      <div class="titles">{{ Ors.title }}</div>
      <el-form label-position="top" label-width="80px">
        <el-form-item
          :label="item.label"
          v-for="(item, index) in Ors.questionList"
          :key="index"
        >
          <el-input
            v-model="item.value"
            v-if="item.type == 3 || item.type == 4"
          ></el-input>
          <div class="block" v-if="item.type == 2">
            <el-slider
              v-model="item.value"
              :step="1"
              show-stops
              :min="item.minValue"
              :max="item.maxValue"
              :marks="marks"
            >
            </el-slider>
          </div>
        </el-form-item>
      </el-form>
      <div class="btn">
        <div class="ljzx_btn" @click="sumbit()">提交</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CountDown from "vue2-countdown";
import {
  queryConsultantInfo,
  queryChatList,
  readSrsQuestion,
  saveBookingSrs,
} from "@/api";
import { emojiMap} from "@/tool/emojiMap";
// import neirong from "./neirong.vue";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { Notification, MessageBox, Message } from "element-ui";
export default {
  name: "talk",
  components: {
    CountDown,
    // neirong,
  },
  data() {
    return {
      api:process.env.VUE_APP_BASE_API,
      path:
        `${process.env.VUE_APP_WSS_API}/websocket/` + getToken("Admin-Token"),
      socket: null,
      textarea: "",
      chatList: [],
      emojiMap: emojiMap,
      // emojiName: emojiName,
      // emojiUrl: emojiUrl,
      zxsInfo: {},
      userHeadImg: "",
      consultantHeadImg: "",
      checkInterval: null,
      userTipsMinute: 0,
      checkMin: 0,
      dialogTableVisible: false,
      marks: {
        0: "0",
        2: "2",
        4: "4",
        6: "6",
        8: "8",
        10: "10",
      },
      Ors: {
        questionList: [],
      },
      id: "",
      reconetTimer:null
    };
  },
  // created() {},s
  computed: {
    field: function () {
      return this.zxsInfo.field ? this.zxsInfo.field.trim().split(",") : [];
    },
    sectLable: function () {
      return this.zxsInfo.sectLable
        ? this.zxsInfo.sectLable.trim().split(",")
        : [];
    },
  },
  created() {
    console.log(11)
    if (!this.$route.query.id) {
      Message({
        message: "请选择一个咨询师",
        type: "error",
      });
      return false;
    }
    queryChatList({
      bookingRecordId: this.$route.query.id,
      isQueryNotRead: false,
    }).then((res) => {
      console.log(res);
      if (res.code == 200) {
        this.chatList = res.data;
        this.scrollBottom();
      }
    });
    this.id = this.$route.query.id;
    this.getOrs()
    this.initSocket();
    this.scrollBottom();
    this.queryZXSinfo();
  },
  activated() {
    this.scrollBottom();
  },
  destroyed() {
    console.log(123)
    this.clearCheckInterval()
    if(this.socket){
      this.socket.close();
    }
  },
  methods: {
    sumbit() {
      let data = {
        id: this.id,
        questionList: this.Ors.questionList,
      };
      for (let i = 0; i < data.questionList.length; i++) {
        if (data.questionList[i].value == null) {
          Message({
            message: `第${i + 1}题没回答，请回答完成后提交`,
            type: "error",
          });
          return false;
        }
      }
      let obj = {
        type: 19,
        messageId: this.getUuid(),
        time: Date.now() / 1000,
        userType: 1,
        roomId:this.id
      };
      let datas = JSON.stringify(obj);
      this.socket.send(datas)
      saveBookingSrs(data).then((res) => {
        if (res.code == 200) {
           this.dialogTableVisible = false;
           this.$forceUpdate()
        }
      });
    },
    getOrs() {
      readSrsQuestion().then((res) => {
        console.log(res);
        this.Ors = res.data;
      });
    },
    inputFn() {
      let obj = {
        type: 15,
        messageId: this.getUuid(),
        time: Date.now() / 1000,
        roomId: this.id,
      };
      let datas = JSON.stringify(obj);
      this.socket.send(datas);
      this.clearCheckInterval();
    },
    blurFn() {
      this.setCheckInterval();
    },
    setCheckInterval() {
      this.checkMin = this.userTipsMinute * 60;
      this.clearCheckInterval();
      this.checkInterval = setInterval(() => {
        console.log(11);
        this.checkMin--;
        if (this.checkMin <= 0) {
          clearInterval(this.checkInterval);
          this.$alert("你已长时间未回复", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.setCheckInterval();
            },
          });
        }
      }, 1000);
    },
    clearCheckInterval() {
      clearInterval(this.checkInterval);
    },
    scrollBottom() {
      this.$nextTick(() => {
        var container = this.$el.querySelector("#chat-box");
        container.scrollTop = container.scrollHeight;
      });
    },
    queryZXSinfo() {
      queryConsultantInfo({ id: this.$route.query.consultantId }).then(
        (res) => {
          this.zxsInfo = res.data;
        }
      );
    },
    countdown_end() {},
    initSocket() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        console.log(this.path);
        this.socket = new WebSocket(this.path);
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
        this.socket.onclose = this.onclose;
      }
    },
    reconnect(){
      this.socket = null;
      if ("WebSocket" in window) {
        this.socket = new WebSocket(
          this.path
        );
      }
    },
    onclose(){
      console.log("关闭链接");
    },
    getUuid() {
      var s = [];
      var hexDigits = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = "-";
      let uuid = s.join("");
      return uuid;
    },
    open() {
      console.log("socket连接成功");
      let obj = {
        type: 1,
        messageId: this.getUuid(),
        time: Date.now() / 1000,
        text: "",
        userType: 1,
        roomId: this.$route.query.id,
      };
      let datas = JSON.stringify(obj);
      this.socket.send(datas);
    },
    error() {
      console.log("连接错误");
      this.reconetTimer =  setInterval(() => {
        if(this.socket.readyState == 3){
          this.reconnect()
          clearInterval(this.reconetTimer)
        }
      }, 1000);
    },
    getMessage(msg) {
      console.log(msg, 11);
      if (msg.data !== "null") {
        let data = JSON.parse(msg.data);
        console.log(data, 22);
        if (data.type == 13) {
          Message({
            message: data.text,
            type: "error",
          });
          return false;
        }
        if(data.type == 17){
          this.clearCheckInterval();
          this.socket.close()
          this.$alert("该账号在其他设备登录", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.clearCheckInterval();
              this.$router.push({path: '/index'})
            },
          });
        }
        if(data.type == 18){
            let that = this
            this.$alert("请填写反馈信息", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                this.clearCheckInterval();
                this.dialogTableVisible = true;
              },
            });
        }
        if (data.type == 5) {
          //结束对话
          this.clearCheckInterval();
        }
        if (data.type == 15) {
          //正在输入
        }
        if (data.type == 8) {
          let text = JSON.parse(data.text);
          this.userHeadImg = text.userHeadImg;
          this.consultantHeadImg = text.consultantHeadImg;
          this.userTipsMinute = text.userTipsMinute;
          this.setCheckInterval();
        }
        if (data.type == 10) {
          this.chatList.push(data);
          let obj = {
            type: 12,
            messageId: data.id,
            time: Date.now() / 1000,
            roomId: this.$route.query.id,
          };
          let datas = JSON.stringify(obj);
          this.socket.send(datas);
          this.scrollBottom();
        }
      }
    },
    send() {
      let obj = {
        type: 10,
        messageId: "lfz" + parseInt(new Date().getTime() / 1000),
        time: parseInt(new Date().getTime() / 1000),
        text: this.textarea,
        userType: 1,
        roomId: this.$route.query.id,
      };
      this.chatList.push(obj);
      this.socket.send(JSON.stringify(obj));
      this.textarea = "";
      this.scrollBottom();
      this.setCheckInterval();
    },
    chooseEmoji(item) {
      this.textarea += item;
    },
  },
};
</script>

<style scoped lang="scss">
.tool_bar {
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 3px 12px 1px rgba(214, 217, 224, 0.3);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: space-between;
  .tab {
    display: flex;
    border-radius: 4px;
    border: 1px solid #f59034;
    .tab_item {
      width: 98px;
      line-height: 30px;
      background: rgba(245, 144, 52, 0.1);
      color: #f59034;
      text-align: center;
      &.active {
        background: #f59034;
        color: #ffffff;
      }
    }
  }
  .countdown {
    img {
      width: 19px;
      height: 19px;
    }
    .countdown {
      margin-left: 8px;
      font-size: 18px;
      color: #333333;
    }
  }
}

.talk_wrap {
  margin-top: 10px;
  padding: 20px;
  background: #ffffff;
  height: calc(100vh - 335px);
  box-sizing: border-box;
  overflow-y: scroll;
  .talk_time {
    color: #8d92a1;
    font-size: 14px;
    text-align: center;
  }
  .msg-box {
    width: 100%;
    margin-top: 14px;
    .msg {
      display: flex;
      position: relative;
      align-items: center;
      width: 60%;
      &.isSelf {
        float: right;
        flex-direction: row-reverse;
      }
      .user-head {
        padding: 0 10px;
        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
      .user-msg {
        padding: 8px;
        background: rgba(0, 90, 159, 0.06);
        border-radius: 0 8px 8px 8px;
        &.self {
          background: rgba(245, 144, 52, 0.2);
          border-radius: 8px 0 8px 8px;
        }
      }
    }
  }
}

.input-box {
  background: #ffffff;
  border-top: 1px solid #e2e2e2;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  .chat-tool-bar {
    margin-bottom: 10px;
    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  textarea {
    border: none;
    outline: none;
    width: 100%;
    resize: none;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  }
  .send_btn {
    width: 60px;
    line-height: 30px;
    background: #f59034;
    border-radius: 4px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
}

.info_card {
  margin: 0 auto;
  display: flex;
  padding: 16px 36px;
  box-sizing: border-box;
  width: 1140px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-shadow: 0px 7px 35px 0px rgba(220, 223, 230, 0.51);
  border-radius: 4px;
  color: #8d92a1;
  .name {
    color: #40404c;
    font-size: 20px;
  }
  .xw {
    font-size: 14px;
    margin-left: 20px;
  }
  .tag {
    line-height: 21px;
    background: #ffffff;
    border: 1px solid #f59034;
    border-radius: 4px;
    text-align: center;
    color: #f59034;
    display: inline-block;
    margin-right: 10px;
    float: right;
  }
  .job_info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 14px;
    img {
      width: 22px;
      height: 22px;
    }
    .time {
      margin-left: 10px;
      margin-right: 20px;
      color: #757b88;
    }
    .des {
      color: #414a60;
    }
  }
}

.emojis {
  height: 160px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.emoji{
  font-size: 20px;
  cursor: pointer;
}
.ly_box {
  display: flex;
  align-items: flex-start;
}
.ly_box > span {
  width: 140px;
}
.ljzx_btn {
  width: 110px;
  line-height: 34px;
  background: #f59034;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.ors_diglog ::v-deep .el-dialog {
  top: 50%;
  margin-top: 0 !important;
  transform: translateY(-50%);
}
.ors_diglog ::v-deep .el-dialog__body {
  padding-top: 0;
}
.ors_diglog .titles {
  font-size: 14px;
  line-height: 28px;
  text-indent: 28px;
}
.ors_diglog .block {
  width: 100%;
}
.ors_diglog .block ::v-deep .el-slider__button {
  border-color: #f59034;
  width: 14px;
  height: 14px;
}
.ors_diglog .block ::v-deep .el-slider__bar {
  background-color: #f59034;
}
.ors_diglog .block ::v-deep .el-slider__marks-text {
  word-break: keep-all;
}
.ors_diglog ::v-deep .el-form {
  padding: 0 20px;
}
.btn {
  margin: 20px 0;
  text-align: center;
  margin-top: 50px;
}
.ljzx_btn {
  margin: auto;
}
</style>
